import {
  Tree,
  PropertyTree,
  CityTree,
  RatePlanTree,
  RatePlanTreeLevelEnum,
  RatePlanTreeLevel,
  UnitTree,
  UnitTypeTree,
  CountryTree,
  BrandTree,
  AreaTree,
} from 'src/@types/tree';
import { Node } from 'react-checkbox-tree';
import { Typography } from '@mui/material';

type Options = {
  lastLevel?: 'u' | 'r';
  levels?: RatePlanTreeLevel[];
  currencies?: string[];
  currentLevelIndex?: number;
  highestLevel?: RatePlanTreeLevel;
  parentValue?: string;
};

export const NodeLabel = ({
  level,
  label,
}: {
  level: RatePlanTreeLevel;
  label: string | JSX.Element;
}) => {
  // const { BRAND, COUNTRY, CITY, AREA, PROPERTY, UNIT_TYPE, UNIT, RATE_PLAN } = RatePlanTreeLevelEnum;
  const _getChipLabel = () => {
    // switch (level) {
    //   case BRAND: return 'brand';
    //   case COUNTRY: return 'country';
    //   case CITY: return 'city';
    //   case AREA: return 'area';
    //   case PROPERTY: return 'property';
    //   case UNIT_TYPE: return 'unit-type';
    //   case UNIT: return 'unit-type';
    //   case RATE_PLAN: return 'rate-plan';
    //   default: return '';
    // }
  };

  return <Typography variant="subtitle2">{label}</Typography>;
};

export const generateCheckBoxTree = (tree: Tree, options?: Options) => {
  let {
    lastLevel = 'r',
    levels = ['b', 'k', 'c', 'a', 'p', 't', lastLevel],
    currencies = [],
    currentLevelIndex = 0,
    highestLevel = levels[currentLevelIndex],
    parentValue = '',
  } = options ?? {};
  const { BRAND, COUNTRY, CITY, AREA, PROPERTY, UNIT_TYPE, UNIT, RATE_PLAN } = RatePlanTreeLevelEnum;
  const { brandsMap, countriesMap, citiesMap, areasMap, propertiesMap, unitTypesMap, ratePlansMap, unitsMap } = extractTreeMap(tree);
  const nextLevelArgs = {
    lastLevel,
    levels,
    currencies,
    currentLevelIndex: currentLevelIndex + 1,
    highestLevel,
  };

  const singleNodeGuard = (level: RatePlanTreeLevel, nodes: Node[]) => {
    if (highestLevel === level && nodes.length === 1) {
      highestLevel = levels[currentLevelIndex + 1];
      nodes = nodes[0].children ?? [];
    }
    return { convertedTree: nodes, currencies };
  };

  switch (levels[currentLevelIndex]) {
    case BRAND:
      const brands = brandsMap
        .map(({ id, name, countries }, index) => {
          const value = `${index}#${BRAND}-${id}`;
          const { convertedTree: children } = generateCheckBoxTree(countries ?? [], {
            ...nextLevelArgs,
            parentValue: value,
          });
          if (children.length === 0) return null;
          return {
            value,
            title: name,
            label: <NodeLabel level={BRAND} label={name} />,
            children,
          };
        })
        .filter((brand) => brand !== null) as Node[];
      return singleNodeGuard(BRAND, brands);
    case COUNTRY:
      const countries = countriesMap
        .map(({ iso2_code, name, cities }) => {
          const value = `${parentValue}#${COUNTRY}-${iso2_code}`;
          const { convertedTree: children } = generateCheckBoxTree(cities ?? [], {
            ...nextLevelArgs,
            parentValue: value,
          });
          if (children.length === 0) return null;
          return {
            value,
            title: name,
            label: <NodeLabel level={COUNTRY} label={name} />,
            children,
          };
        })
        .filter((country) => country !== null) as Node[];
      return singleNodeGuard(COUNTRY, countries);
    case CITY:
      const cities = citiesMap
        .map(({ id, name, areas }) => {
          const value = `${parentValue}#${CITY}-${id}`;
          const { convertedTree: children } = generateCheckBoxTree(areas ?? [], {
            ...nextLevelArgs,
            parentValue: value,
          });
          if (children.length === 0) return null;
          return {
            value,
            title: name,
            label: <NodeLabel level={CITY} label={name} />,
            children,
          };
        })
        .filter((city) => city !== null) as Node[];
      return singleNodeGuard(CITY, cities);
    case AREA:
      const areas = areasMap
        .map(({ id, name, properties }) => {
          const value = `${parentValue}#${AREA}-${id}`;
          const { convertedTree: children } = generateCheckBoxTree(properties ?? [], {
            ...nextLevelArgs,
            parentValue: value,
          });
          if (children.length === 0) return null;
          return {
            value,
            title: name,
            label: <NodeLabel level={AREA} label={name} />,
            children,
          };
        })
        .filter((area) => area !== null) as Node[];
      return singleNodeGuard(AREA, areas);

    case PROPERTY:
      const properties = propertiesMap
        .map((property) => {
          const { id, short_name, unit_types, default_currency = { code: '' } } = property;
          const { code: currency } = default_currency;
          const value = `${parentValue}#${PROPERTY}-${id}`;
          const { convertedTree: children } = generateCheckBoxTree(unit_types ?? [], {
            ...nextLevelArgs,
            parentValue: value,
          });
          if (children.length === 0) return null;
          if (!currencies.includes(currency)) currencies.push(currency);
          return {
            value,
            title: short_name,
            label: <NodeLabel level={PROPERTY} label={short_name} />,
            children,
            currency,
          };
        })
        .filter((property) => property !== null) as Node[];
      return singleNodeGuard(PROPERTY, properties);

    case UNIT_TYPE:
      const unitTypes = unitTypesMap
        .map(({ id, name, rate_plans, units }) => {
          const value = `${parentValue}#${UNIT_TYPE}-${id}`;
          const { convertedTree: children } = generateCheckBoxTree((lastLevel === RATE_PLAN ? rate_plans : units) ?? [], {
            ...nextLevelArgs,
            parentValue: value,
          });
          if (children.length === 0) return null;
          return {
            value,
            title: name,
            label: <NodeLabel level={UNIT_TYPE} label={name} />,
            children,
          };
        })
        .filter((unitTypes) => unitTypes !== null) as Node[];
      return singleNodeGuard(UNIT_TYPE, unitTypes);

    case RATE_PLAN:
      const ratePlans = ratePlansMap.map(({ id, name, tax_rate }) => ({
        value: `${RATE_PLAN}-${id}`,
        title: name,
        tax_rate,
        label: <NodeLabel level={RATE_PLAN} label={name} />,
      })) as Node[];
      return singleNodeGuard(RATE_PLAN, ratePlans);

    case UNIT:
      const units = unitsMap.map(({ id, name }) => ({
        value: `${UNIT}-${id}`,
        title: name,
        label: <NodeLabel level={RATE_PLAN} label={name} />,
      })) as Node[];
      return singleNodeGuard(UNIT, units);

    default:
      return { convertedTree: [], currencies };
  }
};

export const filterTree = (tree: Node[], filters: {
  currency?: string | null,
  taxRateId?: number,
  noTaxRate?: boolean,
}): Node[] =>
  filters.currency === null
    ? tree
    : tree
        .map((branch) => ({
          ...branch,
          ...(branch.children &&
            branch.children.length > 0 && {
              children:
                (filters.currency !== undefined && branch.children[0].value.includes(RatePlanTreeLevelEnum.PROPERTY))
                  ? branch.children.filter((property: any) => property.currency === filters.currency)
                : ((filters.noTaxRate || filters.taxRateId !== undefined) && branch.children[0].value.includes(RatePlanTreeLevelEnum.RATE_PLAN))
                  ? branch.children.filter((ratePlan: any) => {
                      const hasNoTaxRates = filters.noTaxRate ? ratePlan.tax_rate === null : true;
                      const taxRateLimited = filters.taxRateId ? ratePlan.tax_rate?.id === filters.taxRateId : true;
                      if (filters.noTaxRate && filters.taxRateId) return hasNoTaxRates || taxRateLimited;
                      if (filters.noTaxRate) return hasNoTaxRates;
                      return taxRateLimited;
                    })
                  : filterTree(branch.children, filters),
            }),
        }))
        .filter((branch) => branch.children && branch.children.length > 0);

export const getCheckedLeaves = (ids: Array<string | number>, key: RatePlanTreeLevel = 'r') =>
  ids.map((ids) => `${key}-${ids}`);
export const getSelectedLeafIds = (newValues: string[], key: RatePlanTreeLevel = 'r') =>
  newValues.map((value) => +value.split(`${key}-`)[1]).filter((value) => value);

const isBrandTree = (item: Tree): item is BrandTree[] =>
  item[0] && 'countries' in item[0];
const isCountryTree = (item: Tree): item is CountryTree[] =>
  item[0] && 'cities' in item[0];
const isCityTree = (item: Tree): item is CityTree[] =>
  item[0] && 'areas' in item[0];
const isAreaTree = (item: Tree): item is AreaTree[] =>
  item[0] && 'properties' in item[0];
const isPropertyTree = (item: Tree): item is PropertyTree[] => item[0] && 'unit_types' in item[0];
const isUnitTypeTree = (item: Tree): item is UnitTypeTree[] =>
  item[0] && ('rate_plans' in item[0] || 'units' in item[0]);
const isRatePlanTree = (item: Tree): item is RatePlanTree[] =>
  item[0] && ('type' in item[0] || 'name' in item[0]);
const isUnitTree = (item: Tree): item is UnitTree[] => item[0] && 'name' in item[0];

export const extractTreeMap = (tree: Tree) => {
  const map = {
    brandsMap: [] as BrandTree[],
    countriesMap: [] as CountryTree[],
    citiesMap: [] as CityTree[],
    areasMap: [] as AreaTree[],
    propertiesMap: [] as PropertyTree[],
    unitTypesMap: [] as UnitTypeTree[],
    ratePlansMap: [] as RatePlanTree[],
    unitsMap: [] as UnitTree[],
  };

  if (isBrandTree(tree)) {
    map.brandsMap = tree;
    map.countriesMap = map.brandsMap.flatMap(({ countries }) => countries ?? []);
    map.citiesMap = map.countriesMap.flatMap(({ cities }) => cities ?? []);
    map.areasMap = map.citiesMap.flatMap(({ areas }) => areas ?? []);
    map.propertiesMap = map.areasMap.flatMap(({ properties }) => properties ?? []);
    map.unitTypesMap = map.propertiesMap.flatMap(({ unit_types }) => unit_types ?? []);
    map.ratePlansMap = map.unitTypesMap.flatMap(({ rate_plans }) => rate_plans ?? []);
    map.unitsMap = map.unitTypesMap.flatMap(({ units }) => units ?? []);
  } else if (isCountryTree(tree)) {
    map.countriesMap = tree;
    map.citiesMap = map.countriesMap.flatMap(({ cities }) => cities ?? []);
    map.areasMap = map.citiesMap.flatMap(({ areas }) => areas ?? []);
    map.propertiesMap = map.areasMap.flatMap(({ properties }) => properties ?? []);
    map.unitTypesMap = map.propertiesMap.flatMap(({ unit_types }) => unit_types ?? []);
    map.ratePlansMap = map.unitTypesMap.flatMap(({ rate_plans }) => rate_plans ?? []);
    map.unitsMap = map.unitTypesMap.flatMap(({ units }) => units ?? []);
  } else if (isCityTree(tree)) {
    map.citiesMap = tree;
    map.areasMap = map.citiesMap.flatMap(({ areas }) => areas ?? []);
    map.propertiesMap = map.areasMap.flatMap(({ properties }) => properties ?? []);
    map.unitTypesMap = map.propertiesMap.flatMap(({ unit_types }) => unit_types ?? []);
    map.ratePlansMap = map.unitTypesMap.flatMap(({ rate_plans }) => rate_plans ?? []);
    map.unitsMap = map.unitTypesMap.flatMap(({ units }) => units ?? []);
  } else if (isAreaTree(tree)) {
    map.areasMap = tree;
    map.propertiesMap = map.areasMap.flatMap(({ properties }) => properties ?? []);
    map.unitTypesMap = map.propertiesMap.flatMap(({ unit_types }) => unit_types ?? []);
    map.ratePlansMap = map.unitTypesMap.flatMap(({ rate_plans }) => rate_plans ?? []);
    map.unitsMap = map.unitTypesMap.flatMap(({ units }) => units ?? []);
  } else if (isPropertyTree(tree)) {
    map.propertiesMap = tree;
    map.unitTypesMap = map.propertiesMap.flatMap(({ unit_types }) => unit_types ?? []);
    map.ratePlansMap = map.unitTypesMap.flatMap(({ rate_plans }) => rate_plans ?? []);
    map.unitsMap = map.unitTypesMap.flatMap(({ units }) => units ?? []);
  } else if (isUnitTypeTree(tree)) {
    map.unitTypesMap = tree;
    map.ratePlansMap = map.unitTypesMap.flatMap(({ rate_plans }) => rate_plans ?? []);
    map.unitsMap = map.unitTypesMap.flatMap(({ units }) => units ?? []);
  } else if (isRatePlanTree(tree) || isUnitTree(tree)) {
    map.ratePlansMap = tree;
    map.unitsMap = tree;
  }

  return map;
};