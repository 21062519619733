import React, { useEffect, useState } from 'react';
import { addDays, subDays } from 'date-fns';
import { DatePicker, DateRange, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import {
  Autocomplete,
  Dialog,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
} from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import useFilters from 'src/hooks/useFilterState';
import useResponsive from 'src/hooks/useResponsive';
import { fDT, fYearMonthDay } from 'src/utils/formatTime';
import { getDateFilterFromToDate } from 'src/utils/getDateFilterFromTo';
import { DateFilterSelectValue, FilterValue } from 'src/@types/filter';
import Iconify from 'src/components/Iconify';
import InputWrapper from 'src/components/InputWrapper';
import CustomStaticDateRangePicker from '../CustomStaticDateRangePicker';
import CustomDateRangePickerDay from '../../utils/CustomDateRangePickerDay';
import { styled } from '@mui/material/styles';
import { MenuItemStyle, StyledInput } from './filterRow/common';

export type DateCondition = 'specific_date_range' | 'earlier_than' | 'later_than';

interface SelectedType {
  condition: string;
  shortcut: string;
  start: Date | null;
  end: Date | null;
}

type Props = {
  stateKey: string;
  shortcuts: DateFilterSelectValue[];
  value?: SelectedType;
  onChange?: (value: SelectedType) => void;
  fromFilterRow?: boolean;
  title?: string;
};

export const getDateFilterSearchParams = (
  keyParam: string,
  value: {
    condition: DateCondition;
    start: Date;
    end: Date | null;
  }
): { [key: string]: string | null } => {
  const { condition, start, end } = value;
  const startValue = fYearMonthDay(start);
  const endValue = end ? fYearMonthDay(addDays(end, 1)) : null;
  switch (condition) {
    case 'specific_date_range':
      return {
        [`${keyParam}[after]`]: startValue,
        [`${keyParam}[before]`]: endValue,
      };
    case 'later_than':
      return { [`${keyParam}[strictly_after]`]: startValue };
    case 'earlier_than':
      return { [`${keyParam}[strictly_before]`]: startValue };
    default:
      return {};
  }
};

export const CONDITIONS: DateCondition[] = ['specific_date_range', 'earlier_than', 'later_than'];

const PopoverStyle = styled(Popover)(() => ({
  '& .MuiPaper-root': { borderRadius: 16 },
  '& .MuiPickerStaticWrapper-root': {
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 264,
    },
  },
}));
const FilterCustomAccordionDate = ({
  stateKey,
  shortcuts,
  value,
  onChange,
  fromFilterRow = false,
  title,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const { translate, shortDateFormat, currentLang } = useLocales('common');
  const { filtersState, setFiltersState, setAppliedFilters, updateFiltersState } = useFilters();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<SelectedType>({
    condition: value?.condition ?? '',
    shortcut: value?.shortcut ?? 'custom',
    start: value?.start ?? null,
    end: value?.end ?? null,
  });
  const [selectedTemp, setSelectedTemp] = useState<SelectedType>(selected);

  useEffect(() => {
    const currentFilter = filtersState[stateKey] as FilterValue;
    setSelected({
      condition:
        selected.shortcut === 'reset' ? selected.condition : currentFilter?.value?.condition || '',
      shortcut: currentFilter?.value?.shortcut || 'custom',
      start: currentFilter?.value?.start ?? null,
      end: currentFilter?.value?.end ?? null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState[stateKey]]);

  const shortcutsItems = [
    ...shortcuts.map((option) => ({
      label: translate(option),
      value: option,
      getValue: () => {
        const { from } = getDateFilterFromToDate(option);
        const { to } = getDateFilterFromToDate(option);
        return [from, to ? subDays(to, 1) : null];
      },
    })),
    { label: translate('reset'), value: 'reset', getValue: () => [null, null] },
  ] as PickersShortcutsItem<any>[];

  const formatDate = (date: Date) =>
    fDT({
      date,
      pattern: shortDateFormat,
      options: { locale: currentLang.dateLocale },
    });

  const update = (value: { id: string; label: string; value: SelectedType | null } | null) => {
    updateFiltersState({
      type: 'custom-accordion',
      key: stateKey,
      value: value,
      reason: 'replace',
      label: value?.label || '',
    });
  };

  const handleUpdateFilterState = ({ condition, shortcut, end, start }: SelectedType) => {
    if (start) {
      if (condition === 'specific_date_range') {
        if (end) {
          update({
            id: stateKey,
            label: `${translate(condition)} ${formatDate(start)}-${formatDate(end)}`,
            value: {
              condition,
              shortcut,
              start,
              end,
            },
          });
        }
      } else {
        update({
          id: stateKey,
          label: `${translate(condition)} ${formatDate(start)}`,
          value: {
            condition,
            shortcut,
            start,
            end: null,
          },
        });
      }
    }
  };

  const resetSpecificDateRange = () => {
    setAppliedFilters((prev) =>
      prev.filter((appliedFilter) => appliedFilter.stateKey !== stateKey)
    );
    setFiltersState({ ...filtersState, [stateKey]: null });
  };

  const handleConditionChange = (value: string) => {
    setSelected((prev) => ({ ...prev, condition: value }));
    handleUpdateFilterState({
      condition: value,
      shortcut: selected.shortcut,
      start: selected.start,
      end: selected.end,
    });
  };

  const handleDateChange = (date: DateRange<Date> | Date | null, context: any) => {
    const shortcut = context.shortcut?.value || 'custom';
    const startDate = Array.isArray(date) ? date[0] || null : date;
    const endDate = Array.isArray(date) ? date[1] || null : date;
    setSelected((prev) => ({ ...prev, shortcut, start: startDate, end: endDate }));
    handleUpdateFilterState({
      condition: selected.condition,
      shortcut,
      start: startDate,
      end: endDate,
    });
    if (shortcut === 'reset') resetSpecificDateRange();
    if (onChange)
      onChange({ condition: selected.condition, shortcut, start: startDate, end: endDate });
  };

  return (
    <Stack gap={2}>
      {fromFilterRow ? (
        <StyledInput
          select
          open={false}
          size="small"
          label={title}
          sx={{ minWidth: 150 }}
          value={selected.condition}
          active={Boolean(selected.condition)}
          onChange={(event) => {
            setSelectedTemp(selected);
            setSelected({
              condition: event.target.value,
              shortcut: 'custom',
              start: null,
              end: null,
            });
          }}
          InputLabelProps={{ shrink: Boolean(selected.condition) }}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 300 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
            },
            IconComponent: ({ className }) => (
              <Iconify
                className={className}
                icon="mdi:chevron-down"
                top="unset !important"
                right="14px !important"
                sx={(theme) => ({
                  color: `${
                    isOpen ? theme.palette.primary.main : theme.palette.text.disabled
                  } !important`,
                })}
              />
            ),
          }}
        >
          {CONDITIONS.map((option) => ({
            label: translate(option),
            value: option,
          })).map(({ label, value }, index) => {
            return (
              <MenuItemStyle
                key={index}
                value={value}
                data-label={label}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {translate(label)}
              </MenuItemStyle>
            );
          })}
        </StyledInput>
      ) : (
        <InputWrapper title={translate('condition')}>
          <Autocomplete
            fullWidth
            disableClearable
            options={CONDITIONS}
            value={selected.condition}
            onChange={(_e, value) => handleConditionChange(value)}
            getOptionLabel={(option) => translate(option)}
            renderInput={(params) => <TextField {...params} />}
          />
        </InputWrapper>
      )}
      {selected.condition ? (
        selected.condition === 'specific_date_range' ? (
          <>
            {fromFilterRow === false && (
              <TextField
                autoComplete="off"
                value={`${
                  selected.start
                    ? fDT({
                        date: selected.start,
                        pattern: shortDateFormat.format,
                        options: { locale: currentLang.dateLocale },
                      })
                    : ''
                }${selected.start && selected.end ? ' - ' : ''}${
                  selected.end
                    ? fDT({
                        date: selected.end,
                        pattern: shortDateFormat.format,
                        options: { locale: currentLang.dateLocale },
                      })
                    : ''
                }`}
                onClick={() => setIsOpen(true)}
                InputProps={{
                  readOnly: true,
                  placeholder: '',
                  ...(!selected.start && !selected.end && { value: '' }),
                  ...(selected.shortcut !== 'custom' &&
                    selected.shortcut !== 'reset' && {
                      value: translate(selected.shortcut),
                    }),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Iconify icon="ic:round-calendar-today" width={24} height={24} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Dialog
              open={isOpen}
              onClose={() => {
                if (selected.start === null) {
                  setSelected(selectedTemp);
                }
                setIsOpen(false);
              }}
              maxWidth="lg"
            >
              <CustomStaticDateRangePicker
                value={[selected.start, selected.end]}
                onChange={(date, context) => {
                  handleDateChange(date, context);
                  if (date[0] !== null && date[1] !== null) {
                    setIsOpen(false);
                  }
                }}
                slots={{ day: CustomDateRangePickerDay }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  toolbar: {
                    hidden: false,
                    sx: {
                      '& .MuiPickersToolbar-content .MuiTypography-root': { alignSelf: 'center' },
                    },
                  },
                  layout: {
                    sx: {
                      ...(isMobile && {
                        '&.MuiPickersLayout-root': {
                          display: 'flex !important',
                          flexDirection: 'column !important',
                          '& .MuiList-root': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            maxWidth: 'inherit',
                            padding: 1,
                            gap: 0.5,
                            '& .MuiListItem-root': {
                              px: 0,
                              width: 'max-content',
                            },
                          },
                          '& .MuiPickersLayout-contentWrapper': {
                            alignItems: 'center',
                          },
                        },
                      }),
                    },
                  },
                  actionBar: { actions: [] },
                }}
              />
            </Dialog>
          </>
        ) : (
          <>
            {fromFilterRow === false && (
              <TextField
                autoComplete="off"
                value={
                  selected.start
                    ? fDT({
                        date: selected.start,
                        pattern: shortDateFormat.format,
                        options: { locale: currentLang.dateLocale },
                      })
                    : ''
                }
                onClick={() => setIsOpen(true)}
                InputProps={{
                  readOnly: true,
                  placeholder: '',
                  ...(!selected.start && !selected.end && { value: '' }),
                  ...(selected.shortcut !== 'custom' &&
                    selected.shortcut !== 'reset' && {
                      value: translate(selected.shortcut),
                    }),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Iconify icon="ic:round-calendar-today" width={24} height={24} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Dialog
              open={isOpen}
              onClose={() => {
                if (selected.start === null) {
                  setSelected(selectedTemp);
                }
                setIsOpen(false);
              }}
              maxWidth="lg"
            >
              <StaticDatePicker
                value={selected.start}
                onChange={(date, context) => {
                  handleDateChange(date, context);
                  if (date) {
                    setIsOpen(false);
                  }
                }}
                views={['year', 'month', 'day']}
                slotProps={{
                  actionBar: { actions: [] },
                }}
              />
            </Dialog>
          </>
        )
      ) : null}
    </Stack>
  );
};

export default FilterCustomAccordionDate;
